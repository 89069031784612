import { render, staticRenderFns } from "./RepayRecord.vue?vue&type=template&id=21a65aa3&scoped=true"
import script from "./RepayRecord.vue?vue&type=script&lang=js"
export * from "./RepayRecord.vue?vue&type=script&lang=js"
import style0 from "./RepayRecord.vue?vue&type=style&index=0&id=21a65aa3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a65aa3",
  null
  
)

export default component.exports