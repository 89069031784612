<template>
  <div class="repayRecord">
    <mt-header :title="$t('loanRecord')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index" @click="$router.push({ path: `/mine/repaymentInfo?applyId=${item.applyId}` })">
          <div class="wrap">
            <div class="val" v-if="item.contractStatus == 'OVERDUE'" style="color: #F24431;">{{ item.repayAmount | formatMoney }}</div>
            <div class="val" v-else>{{ item.repayAmount | formatMoney }}</div>
            <div class="name">{{ $t('amount') }}</div>
          </div>
          <div class="wrap">
            <div class="val">{{ item.applyDate }}</div>
            <div class="name">{{ $t('applicationDate') }}</div>
          </div>
          <div class="status" v-if="item.contractStatus == 'OVERDUE'" style="background: #F24431;">
            {{ $t('repayment') }}
          </div>
          <div class="status" v-else>
            {{ $t('repayment') }}
          </div>
          <img class="arrow" src="../../assets/item_arrow_icon.png" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { repayList } from '../../utils/api'
export default {
  name: 'RepayRecord',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.loadMore()
      this.$indicator.close();
      this.$NProgress.done();
    },
    async loadMore() {
      await this.$axios({
        method: 'post',
        url: repayList,
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.list = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.repayRecord {
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    padding: 10px;
    li {
      display: flex;
      text-decoration: none;
      border-radius: 12px;
      margin: 10px 0px;
      padding: 20px 0px;
      background-color: white;
      align-items: center;
      justify-content: space-between;
      .arrow {
        height: 18px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .status {
        margin-left: 10px;
        padding: 6px 10px;
        border-radius: 6.5px;
        background: #58C013;
        color: white;
      }
    }
    .wrap {
      background: #fff;
      border-radius: 10px;
      margin-left: 10px;
      text-align: center;
      .name {
        color: #757575;
        font-size: 12px;
      }
      .val {
        color: #000;
        font-size: 15px;
        color: #383838;
        font-weight: bold;
      }
    }
    .title {
      img {
        width: 16px;
        margin: 0 5px;
      }
    }
    .row {
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>